import React, { useState } from "react";
import styled from "styled-components";
import HeaderMenu from "../core/Header/Header";
import Dices from "./Dices";
import Winner from "./Winner";

const Container = styled.div`
  ${(props) => props.theme.Home?.Container};
`;

const Wrapper = styled.div`
  ${(props) => props.theme.Home?.Wrapper};
`;

const bg_app = "/images/app_bg.jpg";

const Home = () => {
  const [isWinner, setIsWinner] = useState(false);

  //const winner_sound = "/sounds/winner_sound.wav";
  const winner_sound = "/sounds/winner_sound_1.mp3";
  const audioWinner = new Audio(winner_sound); // Update the path as needed

  return (
    <Container
      style={{
        backgroundImage: `url(${bg_app})`,
        backgroundSize: "cover",
        overflow: "auto",
      }}
    >
      <Wrapper>
        <HeaderMenu />
        <Dices setIsWinner={setIsWinner} />
        <Winner isWinner={isWinner} audioWinner={audioWinner} />
      </Wrapper>
    </Container>
  );
};

export default Home;

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import ReactDice from "react-dice-complete";
import AdToggle from "./AdToggle";

const breakpoints = {
  smallMobile: "554",
  mobile: "768",
  tablet: "992",
};

// https://www.npmjs.com/package/react-dice-complete

const DiceContainer = styled.div`
  ${(props) => props.theme.Dice?.DiceContainer};
`;
const DiceWrapper = styled.div`
  ${(props) => props.theme.Dice?.DiceWrapper};
`;

const LeftContainer = styled.div`
  ${(props) => props.theme.Dice?.LeftContainer};
`;

const InfoText = styled.div`
  ${(props) => props.theme.Dice?.InfoText};
`;

const InfoImg = styled.img`
  ${(props) => props.theme.Dice?.InfoImg};
`;

const InfoImgCup = styled.img`
  ${(props) => props.theme.Dice?.InfoImgCup};
`;

const Dices = (props) => {
  const { setIsWinner } = props;
  const reactDice = useRef(null);
  const [rollCount, setRollCount] = useState(0);
  const [isClickable, setIsClickable] = useState(true);

  var size = 45;
  var margin = 20;
  if (window.innerWidth <= breakpoints.smallMobile) {
    size = 165;
    margin = 5;
  } else if (window.innerWidth <= breakpoints.mobile) {
    size = 90;
    margin = 45;
  } else if (window.innerWidth <= breakpoints.tablet) {
    size = 80;
    margin = 35;
  }

  //default values
  // const margin = window.innerWidth <= 768 ? 45 : window.innerWidth <= 992 ? 35 : 20;
  const dieSize = (window.innerWidth / window.innerHeight) * size; // px width/height of each dice face
  const numberOfDices = 3; // The number of dice you wish to have
  const dieCornerRadius = 5; // Rounded radius to use for each die
  const rollTime = 1; // time in seconds for the roll animation
  const dotColor = "#FFFFFF"; // hex color code for the face of the die
  const faceColor = "#4680ff"; // hex color code for the face of the die
  const diceMargin = margin; // margin between each die

  const img_dice_number = "/images/img_dice_number.png";
  const img_cup = "/images/img_cup.png";
  const dice_roll_sound = "/sounds/dice_sound.wav";
  const audio = new Audio(dice_roll_sound); // Update the path as needed

  /** Dices Logic Start */
  useEffect(() => {
    // Attach click event listener to the entire document
    document.addEventListener("click", handleFullScreenClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleFullScreenClick);
    };
  }, [isClickable]);

  // Roll dice on full screen click
  const handleFullScreenClick = () => {
    if (isClickable) {
      playSound();
      rollAll();
    }
  };

  const rollAll = () => {
    setRollCount((prevCount) => {
      const newCount = prevCount + 1;

      if (newCount % 30 === 0) {
        reactDice.current.rollAll([6, 6, 6]); // Forcefully set three dice to 6 on the second roll
      } else {
        reactDice.current.rollAll();
      }
      return newCount;
    });
  };

  // Play sound function
  const playSound = () => {
    audio.play();
  };

  const rollDone = (totalValue, values) => {
    // individual die values array: values
    // total dice value: totalValue

    // Success! Show Winner Screen
    if (totalValue == 18) {
      setIsWinner(true);
      setIsClickable(false);
    }
  };
  /** Dices Logic End */

  return (
    <DiceContainer>
      <DiceWrapper>
        <InfoText>
          3 x <InfoImg src={img_dice_number} alt="imgDiceNumber" /> = 1 free{" "}
          <InfoImgCup src={img_cup} alt="imgCup" />
        </InfoText>
        <LeftContainer>
          <ReactDice
            numDice={numberOfDices}
            ref={reactDice}
            rollDone={rollDone}
            dieCornerRadius={dieCornerRadius}
            dotColor={dotColor}
            faceColor={faceColor}
            rollTime={rollTime} //time in seconds for the roll animation
            dieSize={dieSize}
            margin={diceMargin}
          />
        </LeftContainer>
      </DiceWrapper>
      <AdToggle />
    </DiceContainer>
  );
};

export default Dices;

import { css } from "styled-components";

// Theme
export const DiceTheme = {
  name: "Dice",
  DiceContainer: css`
    height: auto;
    @media only screen and (max-width: 1100px) {
      height: 100%;
    }
  `,
  DiceWrapper: css`
    padding: 0 10%;
    height: 48%;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    .die-container {
      margin: 1.8rem !important;
    }
    @media only screen and (max-width: 992px) {
      height: 65%;
    }
    @media only screen and (max-width: 768px) {
      height: 53%;
      .die-container {
        margin: 1.2rem !important;
      }
    }
    @media only screen and (max-width: 554px) {
      height: 60%;
      .die-container {
        margin: 0.8rem !important;
      }
    }
  `,
  LeftContainer: css`
    margin: 2%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1100px) {
      align-items: center;
    }
    @media only screen and (max-width: 768px) {
      height: 35vh;
      align-items: center;
    }
  `,
  InfoText: css`
    font-size: 3.8vh;
    margin-top: 10px;
    white-space: pre-wrap;
    color: dimgray;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 767px) {
      font-size: 4vh;
    }
  `,
  InfoImg: css`
    border: 0.5px solid darkgray;
    border-radius: 3px;
    width: 25px;
    height: 25px;
  `,

  InfoImgCup: css`
    width: 25px;
    height: 27px;
  `,

  AdToggleContainer: css`
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  `,

  LeftAdContent: css`
    width: 50%;
    margin-right: 1.5%;
    text-align: end;
    @media only screen and (max-width: 992px) {
      width: 50%;
    }

    @media only screen and (max-width: 768px) {
      width: 50%;
    }
  `,

  RightAdContent: css`
    width: 50%;
    margin-left: 1.5%;
    display: ${(props) => (props.isSmallSize ? "block" : "flex")};
    text-align: ${(props) => (props.isSmallSize ? "center" : "left")};

    @media only screen and (max-width: 1200px) {
      width: 55%;
    }

    @media only screen and (max-width: 992px) {
      width: 50%;
    }

    @media only screen and (max-width: 768px) {
      width: ${(props) => (props.isSmallSize ? "45%" : "50%")};
    }
  `,

  ContentContainer: css`
    text-align: right;
  `,

  ContentWrapper: css`
    font-size: 2.5vh;
    color: black;
    font-weight: 500;
    @media only screen and (max-width: 992px) {
      font-size: 3vh;
    }

    @media only screen and (max-width: 768px) {
      font-size: 2.8vh;
    }

    @media only screen and (max-width: 554px) {
      font-size: 1.8vh;
    }
  `,

  ImgAd: css`
    width: ${(props) => (props.isSmallSize ? "40%" : "40%")};

    @media only screen and (max-width: 1200px) {
      width: ${(props) => (props.isSmallSize ? "55%" : "35%")};
    }

    @media only screen and (max-width: 992px) {
      width: ${(props) => (props.isSmallSize ? "45%" : "35%")};
    }

    @media only screen and (max-width: 768px) {
      width: ${(props) => (props.isSmallSize ? "50%" : "45%")};
    }
    @media only screen and (max-width: 554px) {
      width: ${(props) => (props.isSmallSize ? "100%" : "65%")};
    }
  `,
  ImgLogo: css`
    width: 45%;

    @media only screen and (max-width: 992px) {
      width: 40%;
    }

    @media only screen and (max-width: 768px) {
      width: 55%;
    }
    @media only screen and (max-width: 554px) {
      width: 80%;
    }
  `,
};
